import React, { useEffect } from "react";
import { LeadTouching, FlwingTouching, Touching } from "../data/Touching.data";
import {
  LeadDocument,
  FlwingDocument,
  Documentary,
} from "../data/Documentary.data";
import { LeadComedy, FlwingComedy, Comedy } from "../data/Comedy.data";
import { LeadShortAd, FlwingShortAd, ShortAd } from "../data/ShortAd.data";
import {
  LeadMusicAndVideo,
  FlwingMusicAndVideo,
  MusicAndVideo,
} from "../data/MusicVideo.data";
import {
  LeadFoodAndBeverage,
  FlwingFoodAndBeverage,
  FoodAndBeverage,
} from "../data/FoodAndBeverage.data";
import { Film } from "../data/Film.data";
import Modal from "./Modal";
import { useState } from "react";
import "../styles/Video.css";
import HorizontalScroll from "react-scroll-horizontal";
import WorkList from "./WorkList";
function getWindowDimensions() {
  const { innerWidth: width } = window;
  return width;
}

const Video = ({ title, classname }) => {
  const [selected, setSelected] = useState(null);
  const [category, setCategory] = useState("");
  const [viewWidth, setViewWidth] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setViewWidth(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="home__content section__content hide_scrollbar">
      <div className="home__content--projects hide_scrollbar">
        {viewWidth > 1024 ? (
          <>
            {title === "FILM" ? (
              <div
                className=" home__content--projects__images commercial__width100"
                style={{ padding: "3rem 5rem", height: "100%" }}
              >
                <div className="row gx-0 pt-5">
                  <div className="col">
                    <p className="home__title">{title}</p>
                  </div>
                  <div
                    className={`position-relative home__line ${classname}`}
                  ></div>
                </div>
                <div
                  className="row"
                  style={{
                    height: "100%",
                    justifyContent: "space-evenly",
                    flexDirection: "column",
                  }}
                >
                  <WorkList
                    onImageClick={setSelected}
                    work={Film}
                    setCategory={setCategory}
                    categoryNow="Film"
                  />
                </div>
              </div>
            ) : (
              <HorizontalScroll reverseScroll={true}>
                <div
                  className=" home__content--projects__images commercial__width100"
                  style={{ padding: "3rem 5rem" }}
                >
                  <div className="row gx-0 pt-5">
                    <div className="col">
                      <p className="home__title">{title}</p>
                    </div>
                    <div
                      className={`position-relative home__line ${classname}`}
                    ></div>
                  </div>
                  <div
                    className="row"
                    style={{
                      height: "100%",
                      justifyContent: "space-evenly",
                      flexDirection: "column",
                    }}
                  >
                    {title === "TOUCHING" && (
                      <>
                        <WorkList
                          onImageClick={setSelected}
                          work={LeadTouching}
                          setCategory={setCategory}
                          categoryNow="Touching"
                        />
                        <WorkList
                          onImageClick={setSelected}
                          work={FlwingTouching}
                          setCategory={setCategory}
                          categoryNow="Touching"
                        />
                      </>
                    )}
                    {title === "F&B" && (
                      <>
                        <WorkList
                          onImageClick={setSelected}
                          work={LeadFoodAndBeverage}
                          setCategory={setCategory}
                          categoryNow="FoodAndBeverage"
                        />
                        <WorkList
                          onImageClick={setSelected}
                          work={FlwingFoodAndBeverage}
                          setCategory={setCategory}
                          categoryNow="FoodAndBeverage"
                        />
                      </>
                    )}
                    {title === "SHORT AD" && (
                      <>
                        <WorkList
                          onImageClick={setSelected}
                          work={LeadShortAd}
                          setCategory={setCategory}
                          categoryNow="ShortAd"
                        />
                        <WorkList
                          onImageClick={setSelected}
                          work={FlwingShortAd}
                          setCategory={setCategory}
                          categoryNow="ShortAd"
                        />
                      </>
                    )}
                    {title === "MUSIC VIDEO" && (
                      <>
                        <WorkList
                          onImageClick={setSelected}
                          work={LeadMusicAndVideo}
                          setCategory={setCategory}
                          categoryNow="MusicAndVideo"
                        />
                        <WorkList
                          onImageClick={setSelected}
                          work={FlwingMusicAndVideo}
                          setCategory={setCategory}
                          categoryNow="MusicAndVideo"
                        />
                      </>
                    )}
                    {title === "DOCUMENTARY" && (
                      <>
                        <WorkList
                          onImageClick={setSelected}
                          work={LeadDocument}
                          setCategory={setCategory}
                          categoryNow="Documentary"
                        />
                        <WorkList
                          onImageClick={setSelected}
                          work={FlwingDocument}
                          setCategory={setCategory}
                          categoryNow="Documentary"
                        />
                      </>
                    )}
                    {title === "COMEDY" && (
                      <>
                        <WorkList
                          onImageClick={setSelected}
                          work={LeadComedy}
                          setCategory={setCategory}
                          categoryNow="Comedy"
                        />
                        <WorkList
                          onImageClick={setSelected}
                          work={FlwingComedy}
                          setCategory={setCategory}
                          categoryNow="Comedy"
                        />
                      </>
                    )}
                    {title === "FILM" && (
                      <>
                        <WorkList
                          onImageClick={setSelected}
                          work={Film}
                          setCategory={setCategory}
                          categoryNow="Film"
                        />
                      </>
                    )}
                  </div>
                </div>
              </HorizontalScroll>
            )}
          </>
        ) : (
          <>
            <div style={{ padding: "0 5rem 3rem" }}>
              <div className="py-5">
                <p className="home__title">{title}</p>
              </div>
              <div>
                {title === "TOUCHING" && (
                  <WorkList
                    onImageClick={setSelected}
                    work={Touching}
                    setCategory={setCategory}
                    categoryNow="Touching"
                  />
                )}
                {title === "F&B" && (
                  <WorkList
                    onImageClick={setSelected}
                    work={FoodAndBeverage}
                    setCategory={setCategory}
                    categoryNow="FoodAndBeverage"
                  />
                )}
                {title === "SHORT AD" && (
                  <WorkList
                    onImageClick={setSelected}
                    work={ShortAd}
                    setCategory={setCategory}
                    categoryNow="ShortAd"
                  />
                )}
                {title === "MUSIC VIDEO" && (
                  <WorkList
                    onImageClick={setSelected}
                    work={MusicAndVideo}
                    setCategory={setCategory}
                    categoryNow="MusicAndVideo"
                  />
                )}
                {title === "DOCUMENTARY" && (
                  <WorkList
                    onImageClick={setSelected}
                    work={Documentary}
                    setCategory={setCategory}
                    categoryNow="Documentary"
                  />
                )}
                {title === "COMEDY" && (
                  <WorkList
                    onImageClick={setSelected}
                    work={Comedy}
                    setCategory={setCategory}
                    categoryNow="Comedy"
                  />
                )}
                {title === "FILM" && (
                  <WorkList
                    onImageClick={setSelected}
                    work={Film}
                    setCategory={setCategory}
                    categoryNow="Film"
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {selected && (
        <Modal
          workId={selected}
          category={category}
          closeModal={() => setSelected(null)}
        />
      )}
    </div>
  );
};
export default Video;
