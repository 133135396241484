export const Film = [
  {
    id: 1,
    image: "Đêm Giặt Ủi.png",
    projectName: "Đêm Giặt Ủi",
    projectInfo: `Director TranThanh`,
    linkVideoId: "700751309",
  },
  {
    id: 2,
    image: "Dada Studio - The Boy Next Door.png",
    projectName: "Dada Studio - The Boy Next Door",
    projectInfo: `PH 1441 x Dada Studio
        EP Linh Lini
        Producer KhaThuy LimNguyen
        Director TranThanh
        Cinematography ThaiQuang
        Camera Operators BaSon ThanhThinn
        Focus Pullers TienTiep Bo
        Production Design TueAnh
        Art Director BuiALoong`,
    linkVideoId: "552961684",
  },
];

export const LeadFilm = [
  ...Film.filter(x => x.id % 2 !== 0)
]

export const FlwingFilm = [
  ...Film.filter(x => x.id % 2 === 0)
]