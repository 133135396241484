import React from "react";
import "../styles/Contact.css";

const Myrmica = () => {
  alert('THANKS TO: ');
  return (
    <section id="contact" className="section">
      <div className="contact__content section__content1">
        <div className="contact__content--projects section__content--projects">
          <div className="contact__content--projects--details contact-email">
            <div className="contact__content--projects--details__img">
              <p>Designer</p>
            </div>
            <div className="contact__content--projects--details__text">
              <span>Phạm Tuấn Anh</span>
            </div>
          </div>
          <div className="contact__content--projects--details contact-phone">
            <div className="contact__content--projects--details__img">
              <p>Tech Lead</p>
            </div>
            <div className="contact__content--projects--details__text">
              <span>Đặng Nhật Anh</span>
            </div>
          </div>
          <div className="contact__content--projects--details contact-facebook">
            <div className="contact__content--projects--details__img">
              <p>Team Lead</p>
            </div>
            <div className="contact__content--projects--details__text">
              <span>Đinh Tiến An</span>
            </div>
          </div>
          <div className="contact__content--projects--details contact-vimeo">
            <div className="contact__content--projects--details__img">
              <p>Developers</p>
            </div>
            <div className="contact__content--projects--details__text">
              <span>Đinh Tiến An, Nguyễn Hữu Huân, ...</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Myrmica;
