import "../styles/WorkItem.css";

import React from "react";

const WorkItem = ({ project, onImageClick, categoryNow, setCategory }) => {
  const onImageSelect = (e) => {
    e.preventDefault();
    onImageClick(project.id);
    setCategory(categoryNow);
  };
  return (
    <div className="workitem--listscroll">
      <div>
        <a href="/" onClick={onImageSelect} title={project.projectName}>
          <img
            src={require(`./../images/${project.linkVideoId}.jpg`).default}
            alt={project.projectName}
          />
        </a>
      </div>
      <div className="home__descript--commercial">
        <a href="/" onClick={onImageSelect}>
          {project.projectName?.length > 27
            ? project.projectName.substr(0, 27) + "..."
            : project.projectName}
        </a>{" "}
        <p>
          {" "}
          {project.projectInfo?.length > 50
            ? project.projectInfo.substr(0, 50) + "..."
            : project.projectInfo}
        </p>
      </div>
    </div>
  );
};

export default WorkItem;
