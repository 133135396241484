export const FoodAndBeverage = [
  {
    id: 1,
    image: "Bia Hà Nội.png",
    projectName: "Bia Hà Nội",
    projectInfo: `EP: Ánh Nguyệt Lê
    Director: Trần Thành
    AD: Tùng Beng
    DP: Thái Quang
    Line Producer: Dung Pham
    PA: Huỳnh Thi
    Cam op: Bá Sơn
    Focus Puller: Tien Tiep
    PD: Tuân Đỗ
    Editor: Linh Zây
    Stylish: Cao Ly`,
    linkVideoId: "713800659",
  },
  {
    id: 2,
    image: "DIAGEO Dấu Ấn Nâng Tầm Tuyệt Tác.png",
    projectName: "DIAGEO Dấu Ấn Nâng Tầm Tuyệt Tác",
    projectInfo: `
    Director: Trần Thành
`,
    linkVideoId: "673864349",
  },
  {
    id: 3,
    image: "Bia Hơi Hà Nội.png",
    projectName: "Bia Hơi Hà Nội",
    projectInfo: `EP: Ánh Nguyệt Lê
    Director: Lê Quân & Trần Thành
    AD: Tùng Beng
    DP: Thái Quang
    Line Producer: Dung Pham
    PA: Huỳnh Thi
    Cam op: Bá Sơn
    Focus Puller: Tien Tiep
    PD: Tuân Đỗ
    Editor: Linh Zây
    Stylish: Cao Ly`,
    linkVideoId: "713804321",
  },
  {
    id: 4,
    image: "[BẾP NHÀ MÓN PHỐ] PHỞ THÌN - HAFELE.png",
    projectName: "[BẾP NHÀ MÓN PHỐ] PHỞ THÌN - HAFELE",
    projectInfo: `Director: Tran Thanh`,
    linkVideoId: "396454148",
  },
  {
    id: 5,
    image: "[BẾP NHÀ MÓN PHỐ] HỦ TIẾU SA ĐÉC - HAFELE.png",
    projectName: "[BẾP NHÀ MÓN PHỐ] HỦ TIẾU SA ĐÉC - HAFELE",
    projectInfo: `Director: Tran Thanh`,
    linkVideoId: "396455784",
  },
  {
    id: 6,
    image: "[BẾP NHÀ MÓN PHỐ] VỊT QUAY THANH XUÂN - HAFELE.png",
    projectName: "[BẾP NHÀ MÓN PHỐ] VỊT QUAY THANH XUÂN - HAFELE",
    projectInfo: `Director: Tran Thanh`,
    linkVideoId: "396456684",
  },
  {
    id: 7,
    image: "[BẾP NHÀ MÓN PHỐ] BÚN CHẢ - HAFELE.png",
    projectName: "[BẾP NHÀ MÓN PHỐ] BÚN CHẢ - HAFELE",
    projectInfo: `Director: Tran Thanh`,
    linkVideoId: "396458004",
  },
  {
    id: 8,
    image: "ACECOOK Snack mì NàNí.png",
    projectName: "ACECOOK Snack mì NàNí",
    projectInfo: `Director: Tran Thanh`,
    linkVideoId: "498238043",
  },
];

export const LeadFoodAndBeverage = [
  ...FoodAndBeverage.filter((x) => x.id % 2 !== 0),
];

export const FlwingFoodAndBeverage = [
  ...FoodAndBeverage.filter((x) => x.id % 2 === 0),
];
