import React from "react";
import "../styles/Contact.css";

const Contact = () => {
  return (
    <section id="contact" className="section">
      <div className="contact__content section__content1">
        <div className="contact__content--projects section__content--projects">
          <a href="mailto:tranthanhvn.films@gmail.com">
            <div className="contact__content--projects--details contact-email">
              <div className="contact__content--projects--details__img">
                <p>EMAIL</p>
              </div>
              <div className="contact__content--projects--details__text">
                <span>tranthanhvn.films@gmail.com</span>
              </div>
            </div>
          </a>
          <a href="tel:+84982945198">
            <div className="contact__content--projects--details contact-phone">
              <div className="contact__content--projects--details__img">
                <p>PHONE</p>
              </div>
              <div className="contact__content--projects--details__text">
                <span>(+84) 982945198</span>
              </div>
            </div>
          </a>
          <a
            href="https://facebook.com/chu.gucci"
            target="_blank"
            rel="noreferrer"
          >
            <div className="contact__content--projects--details contact-facebook">
              <div className="contact__content--projects--details__img">
                <p>FACEBOOK</p>
              </div>
              <div className="contact__content--projects--details__text">
                <span>fb.com/chu.gucci</span>
              </div>
            </div>
          </a>
          <a
            href="https://vimeo.com/tranthanh"
            target="_blank"
            rel="noreferrer"
          >
            <div className="contact__content--projects--details contact-vimeo">
              <div className="contact__content--projects--details__img">
                <p>VIMEO</p>
              </div>
              <div className="contact__content--projects--details__text">
                <span>vimeo.com/tranthanh</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact;
