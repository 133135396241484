import "../styles/WorkList.css";
import React, { useState, useEffect } from "react";
import WorkItem from "./WorkItem";

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return width;
}

const WorkList = ({ onImageClick, work, categoryNow, setCategory }) => {
  const [viewWidth, setViewWidth] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setViewWidth(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return viewWidth > 1024 ? (
    <div className=" home__content--projects__images">
      {work.map((work, idx) => {
        return (
          <div className="home__content--projects__image--wrapper" key={idx}>
            <WorkItem
              project={work}
              categoryNow={categoryNow}
              setCategory={setCategory}
              onImageClick={onImageClick}
            />
          </div>
        );
      })}
    </div>
  ) : (
    <div className=" home__content--projects__images">
      {work.map((work, idx) => {
        return (
          <div key={idx} style={{ width: "100%" }}>
            <WorkItem
              project={work}
              categoryNow={categoryNow}
              setCategory={setCategory}
              onImageClick={onImageClick}
            />
          </div>
        );
      })}
    </div>
  );
};

export default WorkList;
