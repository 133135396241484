import React from "react";
import SideNav from "./SideNav";
import Home from "./Home";
import AboutMe from "./AboutMe";
import Contact from "./Contact";
import Route from "./Route";

import Video from "./Video.component";
import { BrowserRouter as Router } from "react-router-dom";
import Myrmica from "./Myrmica";

const App = () => {
  return (
    <Router>
      <SideNav />
      <Route path="/">
        <Home />
      </Route>
      <Route path="/about-me">
        <AboutMe />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/short-ad">
        <Video title="SHORT AD" classname="-shortad" />
      </Route>
      <Route path="/comedy">
        <Video title="COMEDY" classname="-cy" />
      </Route>
      <Route path="/food-and-beverage">
        <Video title="F&B" classname="-fb" />
      </Route>
      <Route path="/documentary">
        <Video title="DOCUMENTARY" classname="-doc" />
      </Route>
      <Route path="/film">
        <Video title="FILM" classname="-fm" />
      </Route>
      <Route path="/music-and-video">
        <Video title="MUSIC VIDEO" classname="-mv" />
      </Route>
      <Route path="/touching">
        <Video title="TOUCHING" classname="-tch" />
      </Route>
      <Route path="/myrmica">
        <Myrmica />
      </Route>
    </Router>
  );
};

export default App;
