export const ShortAd = [
  {
    id: 1,
    image: "[TVC] VNG Võ Lâm Truyền Kỳ 1 Mobile - Tuyệt Đỉnh Hoa Sơn.png",
    projectName: "[TVC] VNG Võ Lâm Truyền Kỳ 1 Mobile - Tuyệt Đỉnh Hoa Sơn",
    projectInfo: `Client | VNG - Võ Lâm Truyền Kỳ 1 Mobile
      Account: Kim Hue Vo
      Creative Leads: Nguyễn Duy Phương, Truong Thanh Vi
      Script and Creative by VNG Team
      Production | redA
      Director: Tui
      Director's Personal Assistant: Quách Bim
      Executive Producer: Ánh Nguyệt Lê
      Producer: Huỳnh Nga
      Production Assistant: Trần Huỳnh Thi
      1st Assistant Director: Nguyễn Thành Nhân
      2nd Assistant Director: Lê Võ Khả Ái
      Director of Photography: Nguyễn Pha
      Cam Op: Trung Tiến
      Focus Puller: Trí Nguyễn
      Steadicam: Tung Thanh Le Trần Nam
      Gaffer: Phạm Bình Minh (Minh Huế)
      Camera: HNLens
      Light and Grip by HNLens
      Art Director: My Hoàng Đinh
      Props Master: Ngoc Lan
      Props Team: Ngoc Lan Team
      Stylist: Túú Túú
      Make Up & Hair: Đức Hạnh
      Casting: Huỳnh Nga & Nguyễn Võ Bảo Trúc
      Talents: Thái Vũ, Linh Khiếu, Minh Duy, Ngô Vương, Tuấn Đạt, Dương Thuỵ Yến Nhi
      Location: Võ Tuấn Anh & team.
      Post Production | VNG Team
      Producer: Thanhvy Truong
      VFX Lead Artist: Thanhvy Truong`,
    linkVideoId: "560250192",
  },
  {
    id: 2,
    image: "[TVC] VNG FORSAKEN WORLD.png",
    projectName: "[TVC] VNG FORSAKEN WORLD",
    projectInfo: `PH RedA
      Prod Anh Nguyet Le
      Director Tran Thanh
      DP Thai Quang
      AD Nguyen Thanh Nhan`,
    linkVideoId: "546909815",
  },
  {
    id: 3,
    image: "[TVC] Tải Ví VNPAY Săn Vàng Thả Ga - Còn Cái Nịt.png",
    projectName: "[TVC] Tải Ví VNPAY Săn Vàng Thả Ga - Còn Cái Nịt",
    projectInfo: `Production | redA
      Director: Tran Thanh
      Director's Personal Assistant: Quách Bim
      Executive Producer: Ánh Nguyệt Lê
      Producer: Dung Pham
      Production Assistant: Trần Huỳnh Thi
      1st Assistant Director: Tùng Beng
      Director of Photography: Tân Dương
      Cam Op: Tân
      Focus Puller: Huy Phùng
      Camera: HNLens
      Light and Grip by HNLens
      Art Director: Tuân Đỗ
      Props Team: TuanDo Team
      Stylist: Túú Túú
      Make Up & Hair: Trang Xeri
      Talents: Nhật Anh Trắng
      Post Production
      Editor/Grading Linh Zay`,
    linkVideoId: "575249656",
  },
  {
    id: 4,
    image: "VNPAY Săn Kim Cương.png",
    projectName: "VNPAY Săn Kim Cương",
    projectInfo: `Production | redA
    Director: Trần Thành
    Director's Personal Assistant: Quách Bim
    Executive Producer: Ánh Nguyệt Lê
    Producer: Dung Pham
    Producer Assistant: Trần Huỳnh Thi
    1st Assistant Director: Tùng Beng
    Director of Photography: Thái Quang
    Cam Op: Nguyễn Minh
    Focus Puller: Tien Tiep
    Art Director: Tuân Đỗ
    Props Team: TuanDo Team
    Stylist: Cao Ly
    Make Up & Hair: Nguyễn Thùy Linh
    Post Production
    Editor: Linh Zay`,
    linkVideoId: "638510190",
  },
  {
    id: 5,
    image: "LG Sáng Sắc Xuân, Bừng Gắn Kết.png",
    projectName: "LG Sáng Sắc Xuân, Bừng Gắn Kết",
    projectInfo: `Agency: Biz-Eyes
      PH: The July
      Director: Tran Thanh`,
    linkVideoId: "673863874",
  },
  {
    id: 6,
    image: "MOMO Chuyển Tiền Miễn Phí (TẾT).png",
    projectName: "MOMO Chuyển Tiền Miễn Phí (TẾT)",
    projectInfo: `
      Director: Tran Thanh`,
    linkVideoId: "676544989",
  },
  {
    id: 7,
    image: "MOMO Tìm Mọi Loại Vé (TẾT).png",
    projectName: "MOMO Tìm Mọi Loại Vé (TẾT)",
    projectInfo: `
      Director: Tran Thanh`,
    linkVideoId: "676545214",
  },
  {
    id: 8,
    image: "MOMO Dùng Trước Trả Sau (TẾT).png",
    projectName: "MOMO Dùng Trước Trả Sau (TẾT)",
    projectInfo: `
      Director: Tran Thanh`,
    linkVideoId: "676545106",
  },
  {
    id: 9,
    image: "MOMO Tìm Quán Ăn Ngon (TẾT).png",
    projectName: "MOMO Tìm Quán Ăn Ngon (TẾT)",
    projectInfo: `
      Director: Tran Thanh`,
    linkVideoId: "676546705",
  },
  {
    id: 10,
    image: "VNG PUBG Mobile 1.7.png",
    projectName: "VNG PUBG Mobile 1.7",
    projectInfo: `
    Production | Broducer
    Director: Trần Thành
    Director's Personal Assistant: Quách Bim
    PM: Nhu Tran
    Producer: Chou Ngoc Nguyen
    Producer Assistant: Chau nho
    1st Assistant Director: Le Hoang Phuong
    Director of Photography: Puchin Nguyen
    Cam Op: Tuan Tuan Anh Anh
    Art Director: Thanh Thao
    Props Team: NgocLan Team
    Make Up & Hair: Nguyen Nhat Linh
    Post Production:KhanhLP`,
    linkVideoId: "659963378",
  },
  {
    id: 11,
    image: "Bia Hà Nội.png",
    projectName: "Bia Hà Nội",
    projectInfo: `EP: Ánh Nguyệt Lê
    Director: Trần Thành
    AD: Tùng Beng
    DP: Thái Quang
    Line Producer: Dung Pham
    PA: Huỳnh Thi
    Cam op: Bá Sơn
    Focus Puller: Tien Tiep
    PD: Tuân Đỗ
    Editor: Linh Zây
    Stylish: Cao Ly`,
    linkVideoId: "713800659",
  },
  {
    id: 12,
    image: "DIAGEO Dấu Ấn Nâng Tầm Tuyệt Tác.png",
    projectName: "DIAGEO Dấu Ấn Nâng Tầm Tuyệt Tác",
    projectInfo: `
    Director: Trần Thành
`,
    linkVideoId: "673864349",
  },
  {
    id: 13,
    image: "Bia Hơi Hà Nội.png",
    projectName: "Bia Hơi Hà Nội",
    projectInfo: `EP: Ánh Nguyệt Lê
    Director: Lê Quân & Trần Thành
    AD: Tùng Beng
    DP: Thái Quang
    Line Producer: Dung Pham
    PA: Huỳnh Thi
    Cam op: Bá Sơn
    Focus Puller: Tien Tiep
    PD: Tuân Đỗ
    Editor: Linh Zây
    Stylish: Cao Ly`,
    linkVideoId: "713804321",
  },
  {
    id: 14,
    image: "BUFFET PHIM - KEENG MOVIE.png",
    projectName: "BUFFET PHIM - KEENG MOVIE",
    projectInfo: `Director/Writer: Tran Thanh`,
    linkVideoId: "396383880",
  },
  {
    id: 15,
    image: "BẠN DÙNG MOCHA ĐỂ LÀM GÌ.png",
    projectName: "BẠN DÙNG MOCHA ĐỂ LÀM GÌ? - MOCHA",
    projectInfo: `Producer : Trinh Thai Bao
      Director/Writer : Tran Thanh`,
    linkVideoId: "437549601",
  },
  {
    id: 16,
    image: "XGAMING - The Chase x Bé Chanh.png",
    projectName: "XGAMING - The Chase",
    projectInfo: `Written and Produced by Trang Kieu
    Directed by Tran Thanh`,
    linkVideoId: "467027623",
  },
  {
    id: 17,
    image: "REALME C15 - CẶP ĐÔI SIÊU NĂNG LƯỢNG.png",
    projectName: "REALME C15 - CẶP ĐÔI SIÊU NĂNG LƯỢNG",
    projectInfo: `Director: Trần Thành`,
    linkVideoId: "482339832",
  },
  {
    id: 18,
    image: "XGAMING - Gunfight x AS Mobile.png",
    projectName: "XGAMING - Gunfight x AS Mobile",
    projectInfo: `Written and Produced by Trang Kieu
    Directed by Tran Thanh`,
    linkVideoId: "467028614",
  },
  {
    id: 19,
    image: "Chợ Tốt TÂN CỔ GIAO ĐẤU.png",
    projectName: "Chợ Tốt TÂN CỔ GIAO ĐẤU",
    projectInfo: "Written and Directed by Tran Thanh",
    linkVideoId: "473530929",
  },
  {
    id: 20,
    image: "Tải Ví VNPAY Săn Vàng Thả Ga - Alibahoa.png",
    projectName: "Tải Ví VNPAY Săn Vàng Thả Ga - Alibahoa",
    projectInfo: `Production | redA
    Director: Trần Thành
    Director's Personal Assistant: Quách Bim
    Executive Producer: Ánh Nguyệt Lê
    Producer: Dung Pham
    Production Assistant: Trần Huỳnh Thi
    1st Assistant Director: Tùng Beng
    Director of Photography: Tân Dương
    Cam Op: Tân
    Focus Puller: Huy Phùng
    Camera: HNLens
    Light and Grip by HNLens
    Art Director: Tuân Đỗ
    Props Team: TuanDo Team
    Stylist: Túú Túú
    Make Up & Hair: Trang Xeri
    Talents: Nhật Anh Trắng
    Post Production
    Editor/Grading Linh Zay`,
    linkVideoId: "575250385",
  },
  {
    id: 21,
    image: "Luôn sạch khuẩn thơm dịu - Thêm dễ chịu, an tâm.png",
    projectName: "Luôn sạch khuẩn thơm dịu - Thêm dễ chịu, an tâm",
    projectInfo: `Director: Trần Thành`,
    linkVideoId: "673864194",
  },
  {
    id: 22,
    image: "SWINBURNE UNIVERSITY CHANGEMAKERS.png",
    projectName: "SWINBURNE UNIVERSITY CHANGEMAKERS",
    projectInfo: `Client | SWINBURNE VIETNAM
    Script and Creative by Quách Bim, Tranthanh
    
    Director: Trần Thành
    Director's Personal Assistant: Quách Bim
    Producer: Lộc Seven
    Production Assistant: Trần Huỳnh Thi
    1st Assistant Director: Cao Viet Son
    Director of Photography: Thái Quang
    Cam Op: Thành Thinn
    Focus Puller: Huy Nguyễn
    Light and Grip by Cine Hanoi
    Props Master: Nguyễn Như Nhiên
    Props Team: Như Nhiên Team
    Stylist: Lyy Nguyễn
    Make Up & Hair: Thủy Anh
    Casting: Trần Huỳnh Thi
    Talents: Thái Vũ, Yeollan, Nam Việt
    Post Production |
    Editor: Khánh LP
    VFX Lead Artist: Hiếu Dương Quang
    Colorist: Khoa Võ`,
    linkVideoId: "581131344",
  },
  {
    id: 23,
    image: "VALORANT TVC BẮN PHÁ GIỚI HẠN.png",
    projectName: "VALORANT TVC BẮN PHÁ GIỚI HẠN",
    projectInfo: `PM: Như Trần
    Director: Trần Thành
    AD: Hoa Trần
    Creative: Trần Thành - Quách Bim
    DP: KTrung
    Producer: Chou Ngoc Nguyen
    Camera Operator: Nghĩa Bob
    Art Director: Thảo Thanh
    Editor: Khánh LP`,
    linkVideoId: "710851013",
  },
  {
    id: 24,
    image: "THIENLONG Flexoffice.png",
    projectName: "THIENLONG Flexoffice",
    projectInfo: `Party Version
    Dir Tran Thanh
    DP K.Trung`,
    linkVideoId: "527138761",
  },
  {
    id: 25,
    image: "REALME C15 Tiệc văng máy.png",
    projectName: "REALME C15 Tiệc văng máy",
    projectInfo: `Director: Trần Thành`,
    linkVideoId: "482349296",
  },
  {
    id: 26,
    image: "XGAMING - Kidnap x Cô Ngân.png",
    projectName: "XGAMING - Kidnap x Cô Ngân",
    projectInfo: `Party Version
    Dir Tran Thanh
    DP K.Trung`,
    linkVideoId: "467027003",
  },
  {
    id: 27,
    image: "[ADS] Cloud Song - Thuật Sĩ.png",
    projectName: "[ADS] Cloud Song - Thuật Sĩ",
    projectInfo: `Production | redA
      Director: Trần Thành
      Director's Personal Assistant: Quách Bim
      Executive Producer: Ánh Nguyệt Lê
      Producer: Dung Pham
      1st Assistant Director: Nguyễn Thành Nhân
      Director of Photography: Tân Dương`,
    linkVideoId: "638937549",
  },
  {
    id: 28,
    image: "Cloud Song - Cung Thủ.png",
    projectName: "Cloud Song - Cung Thủ",
    projectInfo: `Production | redA
    Director: Trần Thành
    Director's Personal Assistant: Quách Bim
    Executive Producer: Ánh Nguyệt Lê
    Producer: Dung Pham
    1st Assistant Director: Nguyễn Thành Nhân
    Director of Photography: Tân Dương`,
    linkVideoId: "638936297",
  },
  {
    id: 29,
    image: "Dada Studio - The Boy Next Door.png",
    projectName: "Dada Studio - The Boy Next Door",
    projectInfo: `PH 1441 x Dada Studio
        EP Linh Lini
        Producer KhaThuy LimNguyen
        Director TranThanh
        Cinematography ThaiQuang
        Camera Operators BaSon ThanhThinn
        Focus Pullers TienTiep Bo
        Production Design TueAnh
        Art Director BuiALoong`,
    linkVideoId: "552961684",
  },
  {
    id: 30,
    image: "KANGAROO Máy lọc nước phải là Hydrogen.png",
    projectName: "KANGAROO Máy lọc nước phải là Hydrogen",
    projectInfo: `Dir Tran Thanh
    DP Puchin`,
    linkVideoId: "543938348",
  },
  {
    id: 31,
    image: "MaxKleen - Bảo vệ Mạnh mẽ & Dịu êm như chính mẹ!.png",
    projectName: "MaxKleen - Bảo vệ Mạnh mẽ & Dịu êm như chính mẹ!",
    projectInfo: `Short & Fun Commercial with PH 6seconds.
      Director: Trần Thành`,
    linkVideoId: "553267491",
  },
  {
    id: 32,
    image: "VNPAY Săn Kim Cương - Tập Tầm Vông Kí Đầu.png",
    projectName: "VNPAY Săn Kim Cương - Tập Tầm Vông Kí Đầu",
    projectInfo: `Production | redA
    Director: Trần Thành
    Director's Personal Assistant: Quách Bim
    Executive Producer: Ánh Nguyệt Lê
    Producer: Dung Pham
    Producer Assistant: Trần Huỳnh Thi
    1st Assistant Director: Tùng Beng
    Director of Photography: Thái Quang
    Cam Op: Nguyễn Minh
    Focus Puller: Tien Tiep
    Art Director: Tuân Đỗ
    Props Team: TuanDo Team
    Stylist: Cao Ly
    Make Up & Hair: Nguyễn Thùy Linh
    Post Production
    Editor: Linh Zay
    
    Leave the first comment:`,
    linkVideoId: "638505186",
  },
  {
    id: 33,
    image: "THIENLONG Flexoffice.png",
    projectName: "THIENLONG Flexoffice",
    projectInfo: `Party Version
    Dir Tran Thanh
    DP K.Trung`,
    linkVideoId: "527138419",
  },
  {
    id: 34,
    image: "Chợ Tốt - SALE 0%.png",
    projectName: "Chợ Tốt - SALE 0%",
    projectInfo: `Director: Tran Thanh`,
    linkVideoId: "458426046",
  },
  {
    id: 35,
    image: "THIENLONG Flexoffice.png",
    projectName: "THIENLONG Flexoffice",
    projectInfo: `Dir Tran Thanh
    DP K.Trung`,
    linkVideoId: "527137870",
  },
  {
    id: 36,
    image: "HOCMAI Giải Pháp PEN.png",
    projectName: "HOCMAI Giải Pháp PEN",
    projectInfo: `Producer Lộc Seven
    Director Trần Thành
    Cinematography Thái Quang
    Art Director Đỗ Tuân
    Assistant Director Cao Việt Sơn
    Editor Khánh Lp
    VFX Hiếu
    Colorist Khoa Võ`,
    linkVideoId: "555577679",
  },
  {
    id: 37,
    image: "ACECOOK Snack mì NàNí.png",
    projectName: "ACECOOK Snack mì NàNí",
    projectInfo: `Director: Tran Thanh`,
    linkVideoId: "498238043",
  },
  {
    id: 38,
    image: "BEAUTY AND THE BRACELET - K JEWELRY.png",
    projectName: "BEAUTY AND THE BRACELET - K JEWELRY",
    projectInfo: `Director/Writer : Tran Thanh`,
    linkVideoId: "396387043",
  },
  {
    id: 39,
    image: "LIFEBOX VTFC PROMO - VIETTEL TELECOM.png",
    projectName: "LIFEBOX VTFC PROMO - VIETTEL TELECOM",
    projectInfo: `Director/Writer : Tran Thanh`,
    linkVideoId: "396391213",
  },
  {
    id: 40,
    image: "TVC VIETTEL 4G - QUANG HẢI (10 sec).png",
    projectName: "TVC VIETTEL 4G - QUANG HẢI (10 sec)",
    projectInfo: `Directed by Trần Thành
      
          Produced by Đức Lộc
          
          Cinematography Anh Phuong Doan`,
    linkVideoId: "420960309",
  },
];

export const LeadShortAd = [...ShortAd.filter((x) => x.id % 2 !== 0)];

export const FlwingShortAd = [...ShortAd.filter((x) => x.id % 2 === 0)];
