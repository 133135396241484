
export const Documentary = [
  {
    id: 1,
    image: "[BẾP NHÀ MÓN PHỐ] PHỞ THÌN - HAFELE.png",
    projectName: "[BẾP NHÀ MÓN PHỐ] PHỞ THÌN - HAFELE",
    projectInfo: `Director: Tran Thanh`,
    linkVideoId: "396454148",
  },
  {
    id: 2,
    image: "[BẾP NHÀ MÓN PHỐ] HỦ TIẾU SA ĐÉC - HAFELE.png",
    projectName: "[BẾP NHÀ MÓN PHỐ] HỦ TIẾU SA ĐÉC - HAFELE",
    projectInfo: `Director: Tran Thanh`,
    linkVideoId: "396455784",
  },
  {
    id: 3,
    image: "[BẾP NHÀ MÓN PHỐ] VỊT QUAY THANH XUÂN - HAFELE.png",
    projectName: "[BẾP NHÀ MÓN PHỐ] VỊT QUAY THANH XUÂN - HAFELE",
    projectInfo: `Director: Tran Thanh`,
    linkVideoId: "396456684",
  },
  {
    id: 4,
    image: "[BẾP NHÀ MÓN PHỐ] BÚN CHẢ - HAFELE.png",
    projectName: "[BẾP NHÀ MÓN PHỐ] BÚN CHẢ - HAFELE",
    projectInfo: `Director: Tran Thanh`,
    linkVideoId: "396458004",
  },
  {
    id: 5,
    image: "GOOGLE Be Internet AweSome.png",
    projectName: "GOOGLE Be Internet AweSome",
    projectInfo: `Client | GOOGLE
    Agency | RSVP 
    PH | Filmista

    Director: Trần Thành
    Director's Personal Assistant: Quách Bim
    Producer: Khả Thủy
    Production Assistant: Linh Ngo
    Talents: Phương Trang
    1st Assistant Director: Cao Viet Son
    2nd Assistant Director: Nhật Linh
    Director of Photography: Ng K Hoài Trung
    Cam Op: Thành Thinn
    Light and Grip by TL Production
    Props Master: Đỗ Tuân
    Props Team: Do Tuan Team 
    Make Up & Hair: Bảo Hằng

    Post Production |
    Editor: Tao Do
    Voice Record at Katana Studio`,
    linkVideoId: "584517222",
  },
  {
    id: 6,
    image: "Dreams - FECREDIT 2017.png",
    projectName: "Dreams - FECREDIT 2017",
    projectInfo: `Director: Trần Thành`,
    linkVideoId: "442596230",
  },
  {
    id: 7,
    image: "TOKYOLIFE Happy Silence Newyear.png",
    projectName: "TOKYOLIFE Happy Silence Newyear",
    projectInfo: `Prod by Dung Pham
    Dir Tran Thanh
    DP Ha Hoang
    AD Tung Beng`,
    linkVideoId: "546915466",
  },
  // {
  //   id: 8,
  //   image: "Người Giữ Nếp Nhà.png",
  //   projectName: "Người Giữ Nếp Nhà",
  //   projectInfo: `Production | Soulie
  //   Director: Trần Thành
  //   Director's Personal Assistant: Quách Bim
  //   Executive Producer: Huynh Ngoc Huyen
  //   Producer: Linh Nguyen
  //   Production Assistant: Hoang Nguyen Kieu Nuong
  //   1st Assistant Director: Bờm
  //   Director of Photography: Pha Nguyễn
  //   Cam Op: Trung Tiến
  //   Camera: CineSpace
  //   Light and Grip by CineSpace
  //   Art Director: Hà Chie
  //   Post Production
  //   Editor Minh Nhut
  //   Color Truong Quoc Phuong
  //   Sound Mixing Aurea Studio`,
  //   linkVideoId: "660337166",
  // },
];

export const LeadDocument = [
  ...Documentary.filter(x => x.id % 2 !== 0)
]

export const FlwingDocument = [
  ...Documentary.filter(x => x.id % 2 === 0)
]