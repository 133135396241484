import React, { useEffect, useState } from 'react';
import '../styles/Modal.css';
import { Documentary } from '../data/Documentary.data';
import { ShortAd } from 'data/ShortAd.data';
import { Touching } from 'data/Touching.data';
import { MusicAndVideo } from 'data/MusicVideo.data';
import { Comedy } from 'data/Comedy.data';
import { FoodAndBeverage } from 'data/FoodAndBeverage.data';
import { Film } from 'data/Film.data';
const Modal = ({ workId, closeModal, category }) => {
	let work;
	switch (category) {
		case "ShortAd": {
			work = ShortAd[workId - 1];
			break;
		}
		case "FoodAndBeverage": {
			work = FoodAndBeverage[workId - 1];
			break;
		}
		case "Documentary": {
			work = Documentary[workId - 1];
			break;
		}
		case "Film": {
			work = Film[workId - 1];
			break;
		}
		case "Comedy": {
			work = Comedy[workId - 1];
			break;
		}
		case "Touching": {
			work = Touching[workId - 1];
			break;
		}
		default: {
			work = MusicAndVideo[workId - 1];
		}
	}

	const members = work.projectInfo.split('\n');
	console.log(members);

	return workId ? (
		<div className="modal home__modal display-modal">
			<div className="modal__close-btn" onClick={closeModal}>
				<div className="close-btn--line close-btn--line1"></div>
				<div className="close-btn--line close-btn--line2"></div>
			</div>
			<div className="modal__content">
				<iframe
					src={'https://player.vimeo.com/video/' + work.linkVideoId}
					width="100%"
					height="100%"
					frameBorder="0"
					allow="autoplay; fullscreen; picture-in-picture"
					allowFullScreen
					title={work.projectName}
				></iframe>
			</div>
			<div className="modal__description">
				<div className="modal__description--heading">
					<h3>{work.projectName}</h3>
					<h4>22/05/2021</h4>
				</div>
				<div className="modal__description--para modal-scrollbar">
					<div className="modal__description--para__member">
						{members.map((member, idx) => {
							member.trim();
							console.log(member);
							return (
								<div key={idx}>
									<p>{member}</p>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	) : (
		<></>
	);
};

export default Modal;
