export const MusicAndVideo = [
  {
    id: 1,
    image: "Tự Hào Việt Nam.png",
    projectName: "Tự Hào Việt Nam",
    projectInfo: `Music Producer: MASEW
        Music Composer: GONZO - KIM CHI SUN
        Project Lead: Garena Free Fire Việt Nam
        EP: Ánh Nguyệt Lê
        Director: Trần Thành
        AD: Tùng Beng
        Creative: Trần Thành - Quách Bim
        DP: Nghĩa Minh Phan
        Line Producer: Dung Pham
        PA: Huỳnh Thi
        Focus Puller: Huy Phung
        PD: Tuân Đỗ
        Editor: Linh Zây
        Stylish: Cao Ly
        Animation: Callimotion`,
    linkVideoId: "687364864",
  },
  {
    id: 2,
    image: "Vai Ác.png",
    projectName: "Vai Ác",
    projectInfo: `Director: Tran Thanh`,
    linkVideoId: "700751208",
  },
  {
    id: 3,
    image: "Hàng xóm.png",
    projectName: "Hàng xóm",
    projectInfo: `DIRECTOR: Trần Thành
        SCRIPT WRITER: Minh Kiên
        EXECUTIVE PRODUCER: Huynh Ngoc Huyen
        PRODUCER: Linh Nguyễn
        DIRECTOR OF PHOTOGRAPHY: THAI MINH DUONG
        CAMERA OPERATOR: Thành Thinn
        FOCUS PULLER: Tây Lai
        GAFFER: Thông Cine
        ART DIRECTOR: Nguyễn Trung Huy
        1st AD: Ron Tang
        2nd AD: BOM
        PRODUCTION MANAGER: Hoàng Nguyễn Kiều Nương, Bao TQ
        ASSISTANT PRODUCERS: Hải Nam, @Trinh Doan, Hồng Ân
        PROP MASTER: ANH SAO
        MAKEUP ARTIST: DANG TRI VIEN - SOL
        HAIRSTYLIST: POM CHARLES - PHAN UYEN KHIET
        MAKEUP ARTIST ASSISTANT: AI LE - HAI NGOC NG
        PHOTOGRAPHER: ONE.STUDIO (HUU DUONG) - NGHIEM PHIM - TOAN THIEN ANH - MAI NGUYEN HUNG
        BTS: KAMAO
        TEAM GRAPHIC DESIGNER: TUAN MAXX
        STYLIST: THIEN THANH
        STYLIST ASSISTANT: PHAT PHAT - HUY MINH
        CASTING: KUS - YEN XESI - HIM PHAM - LE VY - VIKTOR VIET - QUANG NGUYỄN, CÔNG VINH, TRỌNG NHÂN, NHẬT TÂM, GIA HÂN, BẢO CHÂU, NHÃ KHANH
        COSTUME: HAVE A SMILE DAY - MADAMN - Y218 - VẠC
        MEDIA PARTNER: TIKTOK - YINYANG - ZINGMP3 - METUB NETWORK - DAO ENTERTAINMENT
        LOCATION MANAGER: NGUYEN HUU DUNG
        LIGHTING & EQUIPMENT CREW: CINE HANOI
        CATERING: HA CATERING`,
    linkVideoId: "700749445",
  },
  {
    id: 4,
    image: "11h Tối.png",
    projectName: "11h Tối",
    projectInfo: `Director: Tran Thanh`,
    linkVideoId: "700751209",
  },
  {
    id: 5,
    image: "Chợ Tốt TÂN CỔ GIAO ĐẤU.png",
    projectName: "Chợ Tốt TÂN CỔ GIAO ĐẤU",
    projectInfo: "Written and Directed by Tran Thanh",
    linkVideoId: "473530929",
  },
  {
    id: 7,
    image: "Chợ tốt SALE 0%.png",
    projectName: "Chợ tốt SALE 0%",
    projectInfo: `Director: Tran Thanh`,
    linkVideoId: "458426046",
  },

];
export const LeadMusicAndVideo = [
  ...MusicAndVideo.filter(x => x.id % 2 !== 0)
]

export const FlwingMusicAndVideo = [
  ...MusicAndVideo.filter(x => x.id % 2 === 0)
]