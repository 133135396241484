export const Comedy = [
  {
    id: 1,
    image: "Tải Ví VNPAY Săn Vàng Thả Ga - Còn Cái Nịt.png",
    projectName: "Tải Ví VNPAY Săn Vàng Thả Ga - Còn Cái Nịt",
    projectInfo: `Production | redA
        Director: Tran Thanh
        Director's Personal Assistant: Quách Bim
        Executive Producer: Ánh Nguyệt Lê
        Producer: Dung Pham
        Production Assistant: Trần Huỳnh Thi
        1st Assistant Director: Tùng Beng
        Director of Photography: Tân Dương
        Cam Op: Tân
        Focus Puller: Huy Phùng
        Camera: HNLens
        Light and Grip by HNLens
        Art Director: Tuân Đỗ
        Props Team: TuanDo Team
        Stylist: Túú Túú
        Make Up & Hair: Trang Xeri
        Talents: Nhật Anh Trắng
        Post Production
        Editor/Grading Linh Zay`,
    linkVideoId: "575249656",
  },
  {
    id: 2,
    image: "MOMO Chuyển Tiền Miễn Phí (TẾT).png",
    projectName: "MOMO Chuyển Tiền Miễn Phí (TẾT)",
    projectInfo: `
	Director: Tran Thanh`,
    linkVideoId: "676544989",
  },
  {
    id: 3,
    image: "BUFFET PHIM - KEENG MOVIE.png",
    projectName: "BUFFET PHIM - KEENG MOVIE",
    projectInfo: `Director/Writer: Tran Thanh`,
    linkVideoId: "396383880",
  },
  {
    id: 4,
    image: "REALME C15 - CẶP ĐÔI SIÊU NĂNG LƯỢNG.png",
    projectName: "REALME C15 - CẶP ĐÔI SIÊU NĂNG LƯỢNG",
    projectInfo: `Director: Trần Thành`,
    linkVideoId: "482339832",
  },
  {
    id: 5,
    image: "Tải Ví VNPAY Săn Vàng Thả Ga - Alibahoa.png",
    projectName: "Tải Ví VNPAY Săn Vàng Thả Ga - Alibahoa",
    projectInfo: `Production | redA
    Director: Trần Thành
    Director's Personal Assistant: Quách Bim
    Executive Producer: Ánh Nguyệt Lê
    Producer: Dung Pham
    Production Assistant: Trần Huỳnh Thi
    1st Assistant Director: Tùng Beng
    Director of Photography: Tân Dương
    Cam Op: Tân
    Focus Puller: Huy Phùng
    Camera: HNLens
    Light and Grip by HNLens
    Art Director: Tuân Đỗ
    Props Team: TuanDo Team
    Stylist: Túú Túú
    Make Up & Hair: Trang Xeri
    Talents: Nhật Anh Trắng
    Post Production
    Editor/Grading Linh Zay`,
    linkVideoId: "575250385",
  },
  {
    id: 6,
    image: "REALME C15 Tiệc văng máy.png",
    projectName: "REALME C15 Tiệc văng máy",
    projectInfo: `Director : Tran Thanh`,
    linkVideoId: "482349296",
  },
  {
    id: 7,
    image: "VNPAY Săn Kim Cương - Tập Tầm Vông Kí Đầu.png",
    projectName: "VNPAY Săn Kim Cương - Tập Tầm Vông Kí Đầu",
    projectInfo: `Production | redA
    Director: Trần Thành
    Director's Personal Assistant: Quách Bim
    Executive Producer: Ánh Nguyệt Lê
    Producer: Dung Pham
    Producer Assistant: Trần Huỳnh Thi
    1st Assistant Director: Tùng Beng
    Director of Photography: Thái Quang
    Cam Op: Nguyễn Minh
    Focus Puller: Tien Tiep
    Art Director: Tuân Đỗ
    Props Team: TuanDo Team
    Stylist: Cao Ly
    Make Up & Hair: Nguyễn Thùy Linh
    Post Production
    Editor: Linh Zay
    
    Leave the first comment:`,
    linkVideoId: "638505186",
  },
  {
    id: 8,
    image: "Chợ Tốt - SALE 0%.png",
    projectName: "Chợ Tốt - SALE 0%",
    projectInfo: `Director/Writer : Tran Thanh`,
    linkVideoId: "456679396",
  },
  {
    id: 9,
    image: "HOCMAI Giải Pháp PEN.png",
    projectName: "HOCMAI Giải Pháp PEN",
    projectInfo: `Producer Lộc Seven
    Director Trần Thành
    Cinematography Thái Quang
    Art Director Đỗ Tuân
    Assistant Director Cao Việt Sơn
    Editor Khánh Lp
    VFX Hiếu
    Colorist Khoa Võ`,
    linkVideoId: "555577679",
  },
  {
    id: 10,
    image: "ACECOOK Snack mì NàNí.png",
    projectName: "ACECOOK Snack mì NàNí",
    projectInfo: `Director: Tran Thanh`,
    linkVideoId: "498238043",
  },
  {
    id: 11,
    image: "Chợ Tốt TÂN CỔ GIAO ĐẤU.png",
    projectName: "Chợ Tốt TÂN CỔ GIAO ĐẤU",
    projectInfo: "Written and Directed by Tran Thanh",
    linkVideoId: "473530929",
  },
];


export const LeadComedy = [
  ...Comedy.filter(x => x.id % 2 !== 0)
]

export const FlwingComedy = [
  ...Comedy.filter(x => x.id % 2 === 0)
]