import "../styles/Home.css";
import React, { useState, useEffect, useRef } from "react";
import WorkList from "./WorkList";
import Modal from "./Modal";
import HorizontalScroll from "react-scroll-horizontal";
import { ShortAd } from "../data/ShortAd.data";
import { FoodAndBeverage } from "../data/FoodAndBeverage.data";
import { Documentary } from "data/Documentary.data";
import { MusicAndVideo } from "../data/MusicVideo.data";
import { Film } from "../data/Film.data";
import { Comedy } from "data/Comedy.data";
import { Touching } from "../data/Touching.data";
import Vimeo from "@u-wave/react-vimeo";

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return width;
}

const Home = () => {
  const [selected, setSelected] = useState(null);
  const [category, setCategory] = useState("");
  const [viewWidth, setViewWidth] = useState(getWindowDimensions());
  const playRef = useRef();
  const volumeRef = useRef();
  const [pausedState, setPausedState] = useState(false);
  const [volumeState, setVolumeState] = useState(0);

  const togglePlayVideo = () => {
    if (playRef.current.src.includes("Pause")) {
      playRef.current.src = require("./../images/Play.svg").default;
      setPausedState(true);
      return;
    }

    playRef.current.src = require("./../images/Pause.svg").default;
    setPausedState(false);
  };

  const toggleVolumeVideo = () => {
    if (volumeRef.current.src.includes("Volume-Up")) {
      volumeRef.current.src = require("./../images/Volume-Mute.svg").default;
      setVolumeState(0);
      return;
    }

    volumeRef.current.src = require("./../images/Volume-Up.svg").default;
    setVolumeState(1);
  };

  useEffect(() => {
    function handleResize() {
      setViewWidth(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section id="home" className="section">
      <div className="home__content section__content hide_scrollbar">
        <div className="home__content--projects hide_scrollbar">
          {viewWidth > 1024 ? (
            <HorizontalScroll reverseScroll={true} >
              <div className="home__video-wrapper">
                <div
                  style={{
                    width: "100%",
                    height: "100vh",
                    opacity: 0,
                    position: "absolute",
                    background: "black",
                  }}
                ></div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Vimeo
                    video="650388843"
                    autoplay
                    muted
                    paused={pausedState}
                    volume={volumeState}
                    allowfullscreen={true}
                    loop={true}
                    controls={false}
                    id="650388843"
                    className="video-desktop-wrapper"
                  />
                  <div className="home__indicator-background">
                    <img
                      src={require("./../images/ArrowRight.png").default}
                      alt="Next"
                    />
                  </div>
                  <div className="home__video-control__wrapper">
                    <div className="home__video-control">
                      <div className="home__control-button">
                        <img
                          src={require("./../images/Pause.svg").default}
                          alt="Play"
                          ref={playRef}
                          onClick={togglePlayVideo}
                          id="Play"
                        />
                      </div>
                      <div className="home__control-button">
                        <img
                          src={require("./../images/Volume-Mute.svg").default}
                          alt="Volume"
                          ref={volumeRef}
                          onClick={toggleVolumeVideo}
                          id="Volume"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home__image-preview">
                <div>
                  <div className="row gx-0">
                    <div className="col">
                      <p className="home__title">Short Ad</p>
                    </div>
                    <div className="position-relative home__line -shortad"></div>
                  </div>
                  <WorkList
                    onImageClick={setSelected}
                    work={ShortAd}
                    setCategory={setCategory}
                    categoryNow="ShortAd"
                  />
                </div>
                <div className="home__slide-below-wrapper">
                  <div className="home__slide-below">
                    <div className="row gx-0">
                      <div className="col">
                        <p className="home__title">F&B</p>
                      </div>
                      <div className="position-relative home__line -fb"></div>
                    </div>
                    <WorkList
                      onImageClick={setSelected}
                      work={FoodAndBeverage}
                      setCategory={setCategory}
                      categoryNow="FoodAndBeverage"
                    />
                  </div>
                  <div className="home__slide-below">
                    <div className="row gx-0">
                      <div className="col">
                        <p className="home__title">Documentary</p>
                      </div>
                      <div className="position-relative home__line -doc"></div>
                    </div>
                    <WorkList
                      onImageClick={setSelected}
                      work={Documentary}
                      setCategory={setCategory}
                      categoryNow="Documentary"
                    />
                  </div>
                  <div className="home__slide-below">
                    <div className="row gx-0">
                      <div className="col">
                        <p className="home__title">Film</p>
                      </div>
                      <div className="position-relative home__line -fm"></div>
                    </div>
                    <WorkList
                      onImageClick={setSelected}
                      work={Film}
                      setCategory={setCategory}
                      categoryNow="Film"
                    />
                  </div>
                  <div className="home__slide-below">
                    <div className="row gx-0">
                      <div className="col">
                        <p className="home__title">Comedy</p>
                      </div>
                      <div className="position-relative home__line -cy"></div>
                    </div>
                    <WorkList
                      onImageClick={setSelected}
                      work={Comedy}
                      setCategory={setCategory}
                      categoryNow="Comedy"
                    />
                  </div>
                  <div className="home__slide-below">
                    <div className="row gx-0">
                      <div className="col">
                        <p className="home__title">Touching</p>
                      </div>
                      <div className="position-relative home__line -tch"></div>
                    </div>
                    <WorkList
                      onImageClick={setSelected}
                      work={Touching}
                      setCategory={setCategory}
                      categoryNow="Touching"
                    />
                  </div>
                  <div className="home__slide-below">
                    <div className="row gx-0">
                      <div className="col">
                        <p className="home__title">Music Video</p>
                      </div>
                      <div className="position-relative home__line -mv"></div>
                    </div>
                    <WorkList
                      onImageClick={setSelected}
                      work={MusicAndVideo}
                      setCategory={setCategory}
                      categoryNow="MusicAndVideo"
                    />
                  </div>
                </div>
              </div>
            </HorizontalScroll>
          ) : (
            <div className="home__responsive-mobile-ver">
              <div
                style={{
                  flex: "1 0 100%",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    minHeight: "calc(100vh - 44px)",
                    marginBottom: "1.6rem",
                  }}
                >
                  <div class="preview-background-overlay"></div>
                  <div class="slide-background-overlay"></div>
                  <Vimeo
                    video="650388691"
                    autoplay
                    muted
                    paused={pausedState}
                    volume={volumeState}
                    allowfullscreen={true}
                    loop={true}
                    controls={false}
                    id="650388691"
                    className="testIframe"
                  />
                  <div className="home__indicator-background">
                    <img
                      src={require("./../images/ArrowRight.png").default}
                      alt="Next"
                    />
                  </div>
                  <div className="home__video-control__wrapper">
                    <div className="home__video-control">
                      <div className="home__control-button">
                        <img
                          src={require("./../images/Pause.svg").default}
                          alt="Play"
                          ref={playRef}
                          onClick={togglePlayVideo}
                        />
                      </div>
                      <div className="home__control-button">
                        <img
                          src={require("./../images/Volume-Mute.svg").default}
                          alt="Volume"
                          ref={volumeRef}
                          onClick={toggleVolumeVideo}
                        />

                        {/* <i className="fas fa-volume-mute d-none"></i> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home__image-preview">
                <WorkList
                  onImageClick={setSelected}
                  setCategory={setCategory}
                  categoryNow="ShortAd"
                  work={ShortAd}
                />
                <WorkList
                  onImageClick={setSelected}
                  setCategory={setCategory}
                  categoryNow="FoodAndBeverage"
                  work={FoodAndBeverage}
                />
                <WorkList
                  onImageClick={setSelected}
                  setCategory={setCategory}
                  categoryNow="Documentary"
                  work={Documentary}
                />
                <WorkList
                  onImageClick={setSelected}
                  setCategory={setCategory}
                  categoryNow="Film"
                  work={Film}
                />
                <WorkList
                  onImageClick={setSelected}
                  setCategory={setCategory}
                  categoryNow="Comedy"
                  work={Comedy}
                />
                <WorkList
                  onImageClick={setSelected}
                  setCategory={setCategory}
                  categoryNow="Touching"
                  work={Touching}
                />
                <WorkList
                  onImageClick={setSelected}
                  setCategory={setCategory}
                  categoryNow="MusicAndVideo"
                  work={MusicAndVideo}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {selected && (
        <Modal
          workId={selected}
          category={category}
          closeModal={() => setSelected(null)}
        />
      )}
    </section>
  );
};

export default Home;
