import "../styles/SideNav.css";
import React, { useState, useEffect } from "react";
import Link from "./Link";
import Logo from "../images/logo.png";
function getWindowDimensions() {
  const { innerWidth: width } = window;
  return width;
}
const SideNav = () => {
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState("Home");
  const [viewWidth, setViewWidth] = useState(getWindowDimensions());
  const onButtonClicked = () => {
    setOpen((prevState) => !prevState);
  };
  useEffect(() => {
    function handleResize() {
      setViewWidth(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  console.log(clicked);
  return (
    <aside id="side-nav">
      <div className="side-nav__content">
        <div className="side-nav__content--logo">
          <a href="/">
            <img src={Logo} alt="logo" />
          </a>
        </div>
        <ul
          className={`side-nav__content--links ${open ? "side-nav__content--displayed-links" : ""
            }`}
        >
          <Link
            href="/"
            onLinkClicked={setClicked}
            onClose={setOpen}
            className={`${clicked === "Home" ? "active" : ""} first-link`}
          >
            Home
          </Link>
          {viewWidth > 1024 ? (
            <>
              <div className="dropdown dropend">
                <span
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Video
                </span>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <Link
                      href="/short-ad"
                      onLinkClicked={setClicked}
                      onClose={setOpen}
                      className={`${clicked === "SHORT AD" ? "active" : ""
                        } dropdown-item`}
                    >
                      SHORT AD
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/food-and-beverage"
                      onLinkClicked={setClicked}
                      onClose={setOpen}
                      className={`${clicked === "F&B" ? "active" : ""
                        } dropdown-item`}
                    >
                      F&B
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/documentary"
                      onLinkClicked={setClicked}
                      onClose={setOpen}
                      className={`${clicked === "DOCUMENTARY" ? "active" : ""
                        } dropdown-item`}
                    >
                      DOCUMENTARY
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/film"
                      onLinkClicked={setClicked}
                      onClose={setOpen}
                      className={`${clicked === "FILM" ? "active" : ""
                        } dropdown-item`}
                    >
                      FILM
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/comedy"
                      onLinkClicked={setClicked}
                      onClose={setOpen}
                      className={`${clicked === "COMEDY" ? "active" : ""
                        } dropdown-item`}
                    >
                      COMEDY
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/touching"
                      onLinkClicked={setClicked}
                      onClose={setOpen}
                      className={`${clicked === "TOUCHING" ? "active" : ""
                        } dropdown-item`}
                    >
                      TOUCHING
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/music-and-video"
                      onLinkClicked={setClicked}
                      onClose={setOpen}
                      className={`${clicked === "MUSIC VIDEO" ? "active" : ""
                        } dropdown-item`}
                    >
                      MUSIC VIDEO
                    </Link>
                  </li>
                </ul>
              </div>
            </>
          ) : (<>
            <div class="dropdown nav-item dropend dropdown-toggle">
              <span
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Video
              </span>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link
                  href="/short-ad"
                  onLinkClicked={setClicked}
                  onClose={setOpen}
                  className={`${clicked === "SHORT AD" ? "active" : ""
                    } dropdown-item`}
                >
                  SHORT AD
                </Link>
                </li>
                <li>
                  <Link
                    href="/food-and-beverage"
                    onLinkClicked={setClicked}
                    onClose={setOpen}
                    className={`${clicked === "F&B" ? "active" : ""
                      } dropdown-item`}
                  >
                    F&B
                  </Link>
                </li>
                <li>
                  <Link
                    href="/documentary"
                    onLinkClicked={setClicked}
                    onClose={setOpen}
                    className={`${clicked === "DOCUMENTARY" ? "active" : ""
                      } dropdown-item`}
                  >
                    DOCUMENTARY
                  </Link>
                </li>
                <li>
                  <Link
                    href="/film"
                    onLinkClicked={setClicked}
                    onClose={setOpen}
                    className={`${clicked === "FILM" ? "active" : ""
                      } dropdown-item`}
                  >
                    FILM
                  </Link>
                </li>
                <li>
                  <Link
                    href="/comedy"
                    onLinkClicked={setClicked}
                    onClose={setOpen}
                    className={`${clicked === "COMEDY" ? "active" : ""
                      } dropdown-item`}
                  >
                    COMEDY
                  </Link>
                </li>
                <li>
                  <Link
                    href="/touching"
                    onLinkClicked={setClicked}
                    onClose={setOpen}
                    className={`${clicked === "TOUCHING" ? "active" : ""
                      } dropdown-item`}
                  >
                    TOUCHING
                  </Link>
                </li>
                <li>
                  <Link
                    href="/music-and-video"
                    onLinkClicked={setClicked}
                    onClose={setOpen}
                    className={`${clicked === "MUSIC VIDEO" ? "active" : ""
                      } dropdown-item`}
                  >
                    MUSIC VIDEO
                  </Link>
                </li>
              </ul>
            </div>
          </>)}

          <Link
            href="/about-me"
            onLinkClicked={setClicked}
            onClose={setOpen}
            className={`${clicked === "About Us" ? "active" : ""}`}
          >
            About Us
          </Link>
          <Link
            href="/contact"
            onLinkClicked={setClicked}
            onClose={setOpen}
            className={`${clicked === "Contact" ? "active" : ""}`}
          >
            Contact
          </Link>
        </ul>
        <div
          className={`side-nav__content--hamburger ${open ? "hamburger-line-move" : ""
            }`}
          onClick={onButtonClicked}
        >
          <div className="line line1"></div>
          <div className="line line2"></div>
          <div className="line line3"></div>
          <div className="line line4"></div>
        </div>
      </div>
    </aside>
  );
};

export default SideNav;
