import '../styles/AboutMe.css';
import React from 'react';
import imagesAbout from '../images/aboutme.png';

const AboutMe = () => {
	return (
		<section id="about-me" className="section">
			<div className="about-me__content section__content1">
				<div className="about-me__content--description">
					<div className="about-me__content--description__para">
						<div>
							<h2>Introduce</h2>
							<p>
								After graduating from the Saigon International Film School,
								Thành began his film career, working in a variety of positions,
								as production assistant, assistant director or screenwriter for
								different commercial projects.
							</p>
							<p>
								By the end of 2017, he completed his first film, Laundry Night,
								as a fresh start for his independent film career.
							</p>
							<p>
								In the future, he wishes to continue making his own films, and
								contributing his vision to the world cinema
							</p>
						</div>
						<div>
							<h2>Personality</h2>
							<p>
								Creative, passionate in updating the knowledge and news of the
								Vietnamese as well as international film industry. Especially
								intro Asian cinema, with a good base knowledge regarding
								Taiwanese, Chinese, Japanese, and Korean cinemas
							</p>
						</div>
					</div>
					<div className="about-me__content--description__img">
						<img src={imagesAbout} alt="about-me" />
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutMe;
