import React from 'react';

const Link = ({ href, children, onLinkClicked, onClose, className }) => {
	const onClick = (event) => {
		event.preventDefault();

		onLinkClicked(children);
		onClose(false);
		console.log(children);

		window.history.pushState({}, '', href);

		const navEvent = new PopStateEvent('popstate');
		window.dispatchEvent(navEvent);
	};

	return (
		<a onClick={onClick} href={href} className={className}>
			{children}
		</a>
	);
};

export default Link;
