export const Touching = [
  {
    id: 1,
    image: "Tự Hào Việt Nam.png",
    projectName: "Tự Hào Việt Nam",
    projectInfo: `Music Producer: MASEW
    Music Composer: GONZO - KIM CHI SUN
    Project Lead: Garena Free Fire Việt Nam
    EP: Ánh Nguyệt Lê
    Director: Trần Thành
    AD: Tùng Beng
    Creative: Trần Thành - Quách Bim
    DP: Nghĩa Minh Phan
    Line Producer: Dung Pham
    PA: Huỳnh Thi
    Focus Puller: Huy Phung
    PD: Tuân Đỗ
    Editor: Linh Zây
    Stylish: Cao Ly
    Animation: Callimotion`,
    linkVideoId: "687364864",
  },
  {
    id: 2,
    image: "Vai Ác.png",
    projectName: "Vai Ác",
    projectInfo: `Director: Tran Thanh`,
    linkVideoId: "700751208",
  },
  {
    id: 3,
    image: "Hàng xóm.png",
    projectName: "Hàng xóm",
    projectInfo: `DIRECTOR: Trần Thành
    SCRIPT WRITER: Minh Kiên
    EXECUTIVE PRODUCER: Huynh Ngoc Huyen
    PRODUCER: Linh Nguyễn
    DIRECTOR OF PHOTOGRAPHY: THAI MINH DUONG
    CAMERA OPERATOR: Thành Thinn
    FOCUS PULLER: Tây Lai
    GAFFER: Thông Cine
    ART DIRECTOR: Nguyễn Trung Huy
    1st AD: Ron Tang
    2nd AD: BOM
    PRODUCTION MANAGER: Hoàng Nguyễn Kiều Nương, Bao TQ
    ASSISTANT PRODUCERS: Hải Nam, @Trinh Doan, Hồng Ân
    PROP MASTER: ANH SAO
    MAKEUP ARTIST: DANG TRI VIEN - SOL
    HAIRSTYLIST: POM CHARLES - PHAN UYEN KHIET
    MAKEUP ARTIST ASSISTANT: AI LE - HAI NGOC NG
    PHOTOGRAPHER: ONE.STUDIO (HUU DUONG) - NGHIEM PHIM - TOAN THIEN ANH - MAI NGUYEN HUNG
    BTS: KAMAO
    TEAM GRAPHIC DESIGNER: TUAN MAXX
    STYLIST: THIEN THANH
    STYLIST ASSISTANT: PHAT PHAT - HUY MINH
    CASTING: KUS - YEN XESI - HIM PHAM - LE VY - VIKTOR VIET - QUANG NGUYỄN, CÔNG VINH, TRỌNG NHÂN, NHẬT TÂM, GIA HÂN, BẢO CHÂU, NHÃ KHANH
    COSTUME: HAVE A SMILE DAY - MADAMN - Y218 - VẠC
    MEDIA PARTNER: TIKTOK - YINYANG - ZINGMP3 - METUB NETWORK - DAO ENTERTAINMENT
    LOCATION MANAGER: NGUYEN HUU DUNG
    LIGHTING & EQUIPMENT CREW: CINE HANOI
    CATERING: HA CATERING`,
    linkVideoId: "700749445",
  },
  // {
  //   id: 4,
  //   image: "Người Giữ Nếp Nhà.png",
  //   projectName: "Người Giữ Nếp Nhà",
  //   projectInfo: `Production | Soulie
  //   Director: Trần Thành
  //   Director's Personal Assistant: Quách Bim
  //   Executive Producer: Huynh Ngoc Huyen
  //   Producer: Linh Nguyen
  //   Production Assistant: Hoang Nguyen Kieu Nuong
  //   1st Assistant Director: Bờm
  //   Director of Photography: Pha Nguyễn
  //   Cam Op: Trung Tiến
  //   Camera: CineSpace
  //   Light and Grip by CineSpace
  //   Art Director: Hà Chie
  //   Post Production
  //   Editor Minh Nhut
  //   Color Truong Quoc Phuong
  //   Sound Mixing Aurea Studio`,
  //   linkVideoId: "660337166",
  // },
  {
    id: 4,
    image: "Bùng Sức Trẻ Giành Vinh Quang.png",
    projectName: "Bùng Sức Trẻ Giành Vinh Quang",
    projectInfo: `EP: Ánh Nguyệt Lê
    Director: Trần Thành
    AD: Tùng Beng
    Creative: Trần Thành - Quách Bim
    DP: KTrung x Thái Quang
    Line Producer: Huynh Nga
    PA: Huỳnh Thi
    Camera Operator: Nghĩa Bob
    Focus Puller: Tiến Tiệp
    PD: Tuân Đỗ
    Editor: Linh Zây
    VFX: Moonlight Media`,
    linkVideoId: "710854471",
  },
  {
    id: 5,
    image: "Dreams - FECREDIT 2017.png",
    projectName: "Dreams - FECREDIT 2017",
    projectInfo: `Director: Trần Thành`,
    linkVideoId: "442596230",
  },
  {
    id: 6,
    image: "NGƯỜI THỨ BA - DẦU ĂN KIDDY.png",
    projectName: "NGƯỜI THỨ BA - DẦU ĂN KIDDY",
    projectInfo: `Director: Trần Thành`,
    linkVideoId: "396441347",
  },
  {
    id: 7,
    image: "TOKYOLIFE Happy Silence Newyear.png",
    projectName: "TOKYOLIFE Happy Silence Newyear",
    projectInfo: `Prod by Dung Pham
    Dir Tran Thanh
    DP Ha Hoang
    AD Tung Beng`,
    linkVideoId: "546915466",
  },
];



export const LeadTouching = [
  ...Touching.filter(x => x.id % 2 !== 0)
]

export const FlwingTouching = [
  ...Touching.filter(x => x.id % 2 === 0)
]